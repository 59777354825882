import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import socketMiddleware from 'socket/socketMiddleware';

import appReducers from 'redux/reducers';

const configureStore = () => {
  const store = createStore(
    appReducers,
    composeWithDevTools(applyMiddleware(thunk, socketMiddleware)),
  );
  return { store };
};

export default configureStore();
