import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import rootStore from './redux/store';

import App from './pages';
import Spinner from './components/elements/Spinner';

import 'react-dropdown/style.css';
import './styles/index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-toastify/dist/ReactToastify.css';

const RootComponent = () => {
  const { store } = rootStore;

  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<Spinner fullScreen={true} />}>
          <Route component={App} />
        </Suspense>
      </Router>
    </Provider>
  );
};

ReactDOM.render(<RootComponent />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
