import { SET_USER_DETAILS } from "redux/actions/authentication/actionTypes";

const initialState = {
  errorResponse: {},
  userDetails: {}
};

const app = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      const { userDetails } = action;
      return { ...state, userDetails: userDetails };
    default:
      return state;
  }
};

export default app;
