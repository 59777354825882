const initialState = {
  errorResponse: {},
};

const app = (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default app;
