export const playMediaIcon = (
    <svg
        version="1.1"
        id="play-media"
        xmlns="http://www.w3.org/2000/svg"
        x="0px" y="0px"
        viewBox="0 0 17.804 17.804"
        xmlSpace="preserve">
        <g>
            <g>
                <path d="M2.067,0.043C2.21-0.028,2.372-0.008,2.493,0.085l13.312,8.503c0.094,0.078,0.154,0.191,0.154,0.313
			c0,0.12-0.061,0.237-0.154,0.314L2.492,17.717c-0.07,0.057-0.162,0.087-0.25,0.087l-0.176-0.04
			c-0.136-0.065-0.222-0.207-0.222-0.361V0.402C1.844,0.25,1.93,0.107,2.067,0.043z"/>
            </g>
            <g id="Capa_1_78_">
            </g>
        </g>

    </svg>
);

export const pauseMediaIcon = (
    (
        <svg
            id="Layer_1"
            version="1.1"
            viewBox="60 0 400 400"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path d="M224,435.8V76.1c0-6.7-5.4-12.1-12.2-12.1h-71.6c-6.8,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6   C218.6,448,224,442.6,224,435.8z" />
                <path d="M371.8,64h-71.6c-6.7,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6c6.7,0,12.2-5.4,12.2-12.2V76.1   C384,69.4,378.6,64,371.8,64z" />
            </g>
        </svg>
    )
)

export const closeIcon = (
    <svg
        version="1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px" y="0px"
        viewBox="0 0 512 512"
        height="16px"
        width="16px"
        xmlSpace="preserve">
        <g>
            <path id="XMLID_3_" d="M317.1,256L499.2,73.9c16.7-16.7,16.7-43.7,0-60.4c-16.7-16.7-43.7-16.7-60.4,0L256.7,195.6L74.6,12.5
		c-16.7-16.7-43.7-16.7-60.4,0c-8.4,8.4-13,19.5-13,30.7c0,11.2,4.6,22.3,13,30.7L196.3,256L13.2,438.1c-8.4,8.4-13,19.5-13,30.7
		c0,11.2,4.6,22.3,13,30.7c16.7,16.7,43.7,16.7,60.4,0l183.1-183.1l182.1,182.1c16.7,16.7,43.7,16.7,60.4,0
		c16.7-16.7,16.7-43.7,0-60.4L317.1,256z"/>
        </g>
    </svg>

)

export const truckIconString = (
    `<svg width="100" height="100" style="transform: rotate({DIRECTION_VALUE_GOES_HERE}deg);transform-box: fill-box;transform-origin:center;" viewBox="0 0 211 659" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M172.921 648.577H38.8479C37.7633 648.577 36.8821 647.696 36.8821 646.611V584.93C36.8821 583.846 37.7633 582.964 38.8479 582.964H172.921C174.006 582.964 174.887 583.846 174.887 584.93V646.611C174.881 647.696 173.999 648.577 172.921 648.577Z" fill="#4E5557"/>
<path d="M164.139 655.935H47.8638L43.248 655.923V645.478H167.171L167.159 655.757L164.139 655.935Z" fill="#373C3D"/>
<path d="M137.566 644.282H135.835V657.002H137.566V644.282Z" fill="#8A969A"/>
<path d="M74.9914 644.282H73.2598V657.002H74.9914V644.282Z" fill="#8A969A"/>
<path d="M175.842 658.043H166.635V644.793H183.471V650.414C183.471 654.623 180.057 658.043 175.842 658.043Z" fill="#924739"/>
<path d="M34.5712 658.043H43.7781V644.793H26.9419V650.414C26.9419 654.623 30.356 658.043 34.5712 658.043Z" fill="#924739"/>
<path d="M105.207 0.908936C64.207 0.908936 30.9661 14.7562 30.9661 43.0856V156.477C30.9661 162.467 35.8222 167.329 41.8184 167.329H168.589C174.579 167.329 179.441 162.473 179.441 156.477V43.0856C179.447 14.7562 146.206 0.908936 105.207 0.908936Z" fill="#26638F"/>
<path d="M105.207 12.8767C134.91 12.8767 163.351 19.0577 178.8 36.4115C174.061 11.2374 142.934 0.908936 105.207 0.908936C67.4793 0.908936 36.3583 11.2374 31.6193 36.4115C46.9456 19.0577 75.503 12.8767 105.207 12.8767Z" fill="#4F8FD0"/>
<path d="M105.207 46.3949C69.402 46.3949 40.3701 60.008 40.3701 76.8011V161.795V185.102V259.78C40.3701 274.977 69.3959 287.29 105.207 287.29C141.017 287.29 170.043 274.971 170.043 259.78V185.102V161.795V76.8011C170.043 60.008 141.017 46.3949 105.207 46.3949Z" fill="#4F8FD0"/>
<path d="M159.32 117.345H51.0991V225.646H159.32V117.345Z" fill="#26638F"/>
<path d="M105.207 147.751C135.093 147.751 159.32 134.137 159.32 117.345C159.32 100.552 135.093 86.9385 105.207 86.9385C75.3205 86.9385 51.093 100.552 51.093 117.345C51.093 134.137 75.3205 147.751 105.207 147.751Z" fill="#26638F"/>
<path d="M105.207 252.299C135.093 252.299 159.32 243.597 159.32 232.862C159.32 222.127 135.093 213.425 105.207 213.425C75.3205 213.425 51.093 222.127 51.093 232.862C51.093 243.597 75.3205 252.299 105.207 252.299Z" fill="#C8DB79"/>
<path d="M52.042 215.009L40.3701 252.022V252.564C40.3701 255.719 41.6827 258.733 43.9814 261.555L57.9889 217.129L52.042 215.009Z" fill="#26638F"/>
<path d="M170.043 252.508L158.217 215.009L152.27 217.123L166.321 261.678C168.687 258.819 170.043 255.762 170.043 252.558V252.508Z" fill="#26638F"/>
<path d="M57.995 114.898L41.7443 63.3605C40.8507 65.3695 40.3762 67.4524 40.3762 69.5847V80.0056L52.0481 117.018L57.995 114.898Z" fill="#26638F"/>
<path d="M158.217 117.012L170.043 79.5125V69.5785C170.043 67.3784 169.519 65.2462 168.576 63.1755L152.27 114.892L158.217 117.012Z" fill="#26638F"/>
<path d="M170.043 160.667H40.3762V167.329H170.043V160.667Z" fill="#26638F"/>
<path d="M30.9722 65.8687L7.21547 72.0929C1.51509 73.9724 -1.1718 78.1075 0.843364 81.8975L2.04507 84.153L19.0784 79.1552L22.2706 75.0078L30.9722 72.3332V65.8687Z" fill="#3350AB"/>
<path d="M179.447 65.8687L203.204 72.0929C208.904 73.9724 211.591 78.1075 209.576 81.8975L208.374 84.153L191.341 79.1552L188.149 75.0078L179.447 72.3332V65.8687Z" fill="#3350AB"/>
<path d="M170.043 167.231H40.3763V174.552H170.043V167.231Z" fill="#4F8FD0"/>
<path d="M183.447 639.352H26.9727C25.8696 639.352 24.9822 638.458 24.9822 637.361V172.604C24.9822 171.994 25.4752 171.501 26.0853 171.501H184.328C184.938 171.501 185.431 171.994 185.431 172.604V637.361C185.437 638.464 184.544 639.352 183.447 639.352Z" fill="#A66353"/>
<path d="M175.509 182.452H34.7806V626.971H175.509V182.452Z" fill="#894833"/>
<path d="M60.7066 202.585H58.3032V596.849H60.7066V202.585Z" fill="#A66353"/>
<path d="M91.1744 202.585H88.771V596.849H91.1744V202.585Z" fill="#A66353"/>
<path d="M121.642 202.585H119.239V596.849H121.642V202.585Z" fill="#A66353"/>
<path d="M152.11 202.585H149.707V596.849H152.11V202.585Z" fill="#A66353"/>
<path d="M125.74 126.823H84.6791V147.578H125.74V126.823Z" fill="#467CBF"/>
<path d="M105.207 138.359C116.547 138.359 125.74 133.194 125.74 126.823C125.74 120.451 116.547 115.286 105.207 115.286C93.8662 115.286 84.673 120.451 84.673 126.823C84.673 133.194 93.8662 138.359 105.207 138.359Z" fill="#467CBF"/>
</svg>`
)

export const carIconString = (
    `<svg width="100" height="100" style="transform: rotate({DIRECTION_VALUE_GOES_HERE}deg);transform-box: fill-box;transform-origin:center;" viewBox="0 0 167 314" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.9914 86.6377L6.32616 94.988C1.59947 97.5085 -0.625225 103.061 1.04483 108.145L2.03701 111.177L16.1616 104.466L18.8115 98.9074L20.4446 96.1712L21.9914 86.6377Z" fill="#3350AB"/>
    <path d="M145.502 86.6377L161.167 94.988C165.894 97.5085 168.118 103.061 166.448 108.145L165.456 111.177L151.332 104.466L148.682 98.9074L147.049 96.1712L145.502 86.6377Z" fill="#3350AB"/>
    <path d="M146.537 115.54L149.212 57.9694C149.471 52.4354 148.608 46.9076 146.679 41.7557L142.18 29.7202C135.512 11.8733 120.698 0.380127 104.361 0.380127H63.1267C46.7897 0.380127 31.9749 11.8733 25.307 29.7202L20.8083 41.7557C18.8856 46.9076 18.0228 52.4354 18.2755 57.9694L20.95 115.54V198.273L18.2755 255.844C18.0166 261.378 18.8794 266.905 20.8083 272.057L25.307 284.093C31.9749 301.94 46.7897 313.433 63.1267 313.433H104.361C120.698 313.433 135.512 301.94 142.18 284.093L146.679 272.057C148.602 266.905 149.464 261.378 149.212 255.844L146.537 198.273V115.54Z" fill="#26638F"/>
    <path d="M83.8022 63.2136C52.3792 63.2136 26.9031 75.1629 26.9031 89.9037V164.502V184.955V250.501C26.9031 263.836 52.3792 274.645 83.8022 274.645C115.225 274.645 140.701 263.836 140.701 250.501V184.949V164.496V89.8976C140.701 75.1629 115.225 63.2136 83.8022 63.2136Z" fill="#4F8FD0"/>
    <path d="M131.291 131.816H36.3134V226.867H131.291V131.816Z" fill="#467CBF"/>
    <path d="M83.8022 158.506C110.03 158.506 131.291 146.556 131.291 131.816C131.291 117.075 110.03 105.125 83.8022 105.125C57.5748 105.125 36.3134 117.075 36.3134 131.816C36.3134 146.556 57.5748 158.506 83.8022 158.506Z" fill="#467CBF"/>
    <path d="M83.8022 243.925C110.03 243.925 131.291 236.288 131.291 226.867C131.291 217.446 110.03 209.809 83.8022 209.809C57.5748 209.809 36.3134 217.446 36.3134 226.867C36.3134 236.288 57.5748 243.925 83.8022 243.925Z" fill="#467CBF"/>
    <path d="M47.8065 4.97117C47.8065 4.97117 47.2827 5.75998 46.383 7.06645C41.9397 5.59359 28.4437 12.9702 25.5288 21.111C23.4643 26.8915 23.3288 30.4473 23.9697 32.1851C22.3982 31.6305 21.4615 27.6618 24.5305 19.102C27.9384 9.57462 45.0149 1.03329 47.8065 4.97117Z" fill="#4F8FD0"/>
    <path d="M143.53 32.1912C144.165 30.4533 144.029 26.8975 141.965 21.117C139.044 12.9762 125.56 5.59964 121.111 7.0725C120.217 5.76603 119.693 4.97722 119.693 4.97722C122.485 1.03317 139.555 9.58067 142.969 19.1142C146.026 27.6555 145.101 31.6304 143.53 32.1912Z" fill="#4F8FD0"/>
    <path d="M46.3829 7.0727C42.0753 13.3709 29.1523 31.7169 24.5242 32.2592C24.3332 32.2838 24.1422 32.2592 23.9634 32.1914C23.3225 30.4535 23.4581 26.8977 25.5226 21.1172C28.4436 12.9765 41.9397 5.59369 46.3829 7.0727Z" fill="#4F8FD0"/>
    <path d="M143.53 32.1915C143.345 32.2593 143.154 32.2839 142.963 32.2593C138.341 31.717 125.418 13.371 121.104 7.07283C125.553 5.59998 139.043 12.9766 141.958 21.1173C144.029 26.8978 144.164 30.4536 143.53 32.1915Z" fill="#4F8FD0"/>
    <path d="M43.2214 11.6146C39.6964 16.7727 28.5606 31.8155 24.7707 32.2592C24.6166 32.2777 24.4564 32.2592 24.3085 32.2038C23.7846 30.7802 23.8956 27.8715 25.5841 23.1386C27.9814 16.4769 39.5855 10.4067 43.2214 11.6146Z" fill="#B8B8B8"/>
    <path d="M124.376 11.6146C127.901 16.7727 139.037 31.8155 142.827 32.2592C142.981 32.2777 143.142 32.2592 143.289 32.2038C143.813 30.7802 143.702 27.8715 142.014 23.1386C139.617 16.4769 128.012 10.4067 124.376 11.6146Z" fill="#B8B8B8"/>
    <path d="M61.9065 0.380127L50.9186 57.119L69.9609 0.380127H61.9065Z" fill="#467CBF"/>
    <path d="M105.587 0.380127L116.575 57.119L97.5261 0.380127H105.587Z" fill="#467CBF"/>
    <path d="M37.1391 217.531L26.8969 250.014V250.488C26.8969 253.255 28.0493 255.899 30.0644 258.377L42.3588 219.386L37.1391 217.531Z" fill="#467CBF"/>
    <path d="M140.701 250.445L130.323 217.537L125.104 219.392L137.435 258.5C139.512 255.992 140.701 253.305 140.701 250.495V250.445Z" fill="#467CBF"/>
    <path d="M42.365 129.671L28.1048 84.4377C27.3221 86.2002 26.9031 88.0244 26.9031 89.8978V99.0431L37.1453 131.526L42.365 129.671Z" fill="#467CBF"/>
    <path d="M130.323 131.526L140.701 98.6117V89.8917C140.701 87.9628 140.245 86.0894 139.413 84.2776L125.104 129.665L130.323 131.526Z" fill="#467CBF"/>
    <path d="M140.701 169.839H26.8969V175.687H140.701V169.839Z" fill="#467CBF"/>
    <path d="M52.7489 303.875C47.3259 301.779 42.1123 297.94 37.817 293.183L33.078 294.637C38.31 300.707 44.8239 305.619 51.7075 308.417L52.7489 303.875Z" fill="#924739"/>
    <path d="M114.744 303.875C120.167 301.779 125.381 297.94 129.676 293.183L134.415 294.637C129.183 300.707 122.669 305.619 115.786 308.417L114.744 303.875Z" fill="#924739"/>
    <path d="M25.3069 284.093L26.8968 259.923L30.5266 294.397L25.3069 284.093Z" fill="#467CBF"/>
    <path d="M142.106 284.093L140.516 259.923L136.886 294.397L142.106 284.093Z" fill="#467CBF"/>
    </svg>`
)

export const busIconString = (
    `<svg viewBox="0 0 216 579" fill="none" xmlns="http://www.w3.org/2000/svg" width="100" height="100" style="transform: rotate({DIRECTION_VALUE_GOES_HERE}deg);transform-box: fill-box;transform-origin:center;">
    <path d="M39.9747 120.535L19.0281 131.72C12.7115 135.097 9.7288 142.529 11.9658 149.345L13.2969 153.4L32.1852 144.415L35.7287 136.964L37.9164 133.298L39.9747 120.535Z" fill="#3350AB"/>
    <path d="M186.595 120.535L207.535 131.72C213.852 135.097 216.834 142.529 214.597 149.345L213.266 153.4L194.378 144.415L190.835 136.964L188.647 133.298L186.595 120.535Z" fill="#3350AB"/>
    <path d="M189.867 79.5293C189.867 79.2397 189.861 78.9439 189.849 78.6481C189.516 44.4273 168.039 18.6862 139.574 18.4705L87.0629 18.452C60.342 18.452 38.4833 41.9684 36.8502 75.4251C36.8441 75.5606 36.8379 75.69 36.8317 75.8256C36.8256 75.9365 36.8194 76.0413 36.8194 76.1522C36.8133 76.2323 36.8133 76.3125 36.8071 76.3926C36.8009 76.4727 36.8009 76.5466 36.8009 76.6268C36.7948 76.7438 36.7948 76.8609 36.7886 76.9719C36.764 77.7668 36.7516 78.568 36.7516 79.3753C36.8194 92.0086 36.6838 173.712 36.6838 173.712C36.6838 174.174 36.7147 174.63 36.7701 175.08V545.081C36.7701 579.881 58.7952 577.164 85.9721 577.164H140.64C167.811 577.164 191.488 582.611 189.842 545.081V174.784C189.873 174.433 189.898 174.069 189.898 173.712V171.068V111.729L189.867 79.5293Z" fill="#26638F"/>
    <path d="M168.797 145.222H57.7661V569.836H168.797V145.222Z" fill="#467CBF"/>
    <path d="M147.228 236.09H79.3288C77.6403 236.09 76.2722 234.722 76.2722 233.033V205.585C76.2722 203.896 77.6403 202.528 79.3288 202.528H147.228C148.917 202.528 150.285 203.896 150.285 205.585V233.033C150.285 234.722 148.917 236.09 147.228 236.09Z" fill="#26638F"/>
    <path d="M58.3885 108.5L89.7191 32.25L92.2951 32.9649L60.9707 109.214" fill="#467CBF"/>
    <path d="M165.599 109.214L134.275 32.9649L136.85 32.25L168.181 108.5" fill="#467CBF"/>
    <path d="M87.0383 18.4458C68.7231 18.4458 52.7004 29.4953 43.9003 47.2189C54.2287 39.9409 67.0223 35.7935 80.8696 35.7935H145.7C159.553 35.7935 172.341 39.9409 182.669 47.2189C173.869 29.4953 157.846 18.4458 139.531 18.4458" fill="#4F8FD0"/>
    <path d="M168.212 149.123C167.522 151.28 163.467 150.88 159.473 150.294C150.494 148.975 133.818 147.083 112.083 147.083C89.0164 147.083 73.9859 148.747 65.7835 150.042C61.6854 150.689 57.4887 151.021 56.8971 148.791L49.4219 110.151C48.9782 108.493 50.7099 106.891 53.6247 106.232C61.7347 104.395 80.3272 101.246 112.484 101.246C142.945 101.246 163.818 104.864 172.895 106.823C175.921 107.477 177.665 109.153 177.117 110.854L168.212 149.123Z" fill="#4F8FD0"/>
    <path d="M174.356 304.303V235.461L184.056 221.096C184.056 244.84 184.056 270.619 184.056 297.019L174.356 304.303Z" fill="#4F8FD0"/>
    <path d="M184.056 207.353L174.356 221.718V164L184.056 129.539C184.056 148.458 184.056 175.659 184.056 207.353Z" fill="#4F8FD0"/>
    <path d="M174.356 386.142V314.145L184.056 306.861C184.056 332.096 184.056 357.702 184.056 382.475L174.356 386.142Z" fill="#4F8FD0"/>
    <path d="M174.356 467.778V394.696L184.056 391.029C184.056 417.842 184.056 443.473 184.056 466.305L174.356 467.778Z" fill="#4F8FD0"/>
    <path d="M174.356 549.358V475.919L184.056 474.446C184.056 508.414 184.056 535.301 184.056 549.358H174.356Z" fill="#4F8FD0"/>
    <path d="M52.2136 304.303V235.461L42.5137 221.096C42.5137 244.84 42.5137 270.619 42.5137 297.019L52.2136 304.303Z" fill="#4F8FD0"/>
    <path d="M42.5137 207.353L52.2136 221.718V164L42.5137 129.545C42.5137 148.458 42.5137 175.659 42.5137 207.353Z" fill="#4F8FD0"/>
    <path d="M52.2136 386.142V314.145L42.5137 306.861C42.5137 332.096 42.5137 357.702 42.5137 382.475L52.2136 386.142Z" fill="#4F8FD0"/>
    <path d="M52.2136 467.778V394.696L42.5137 391.029C42.5137 417.842 42.5137 443.473 42.5137 466.305L52.2136 467.778Z" fill="#4F8FD0"/>
    <path d="M52.2136 549.358V475.919L42.5137 474.446C42.5137 508.414 42.5137 535.301 42.5137 549.358H52.2136Z" fill="#4F8FD0"/>
    <path d="M139.574 96.7291C139.574 96.7291 127.083 94.6708 113.081 94.6769C98.4452 94.6831 87.0013 96.7291 87.0013 96.7291L92.0485 82.6229H134.946L139.574 96.7291Z" fill="#467CBF"/>
    <path d="M165.962 578.427H60.8473L56.7924 577.681V573.374H169.376V577.977L165.962 578.427Z" fill="#373C3D"/>
    <path d="M39.266 561.517C42.1193 571.377 48.3804 575.765 56.7924 577.681V561.517H39.266Z" fill="#924739"/>
    <path d="M188.413 561.517H169.438V577.984C178.577 576.233 185.399 571.932 188.413 561.517Z" fill="#924739"/>
    <path d="M142.569 572.283H141.066V578.427H142.569V572.283Z" fill="#8A969A"/>
    <path d="M84.3884 572.283H82.8848V578.427H84.3884V572.283Z" fill="#8A969A"/>
    </svg>`
)

export const tankerTruckIconString = (
    `<svg width="100" height="100" style="transform: rotate({DIRECTION_VALUE_GOES_HERE}deg);transform-box: fill-box;transform-origin:center;" viewBox="0 0 211 662" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M172.279 643.917H38.2057C37.1211 643.917 36.2399 643.035 36.2399 641.951V580.27C36.2399 579.185 37.1211 578.304 38.2057 578.304H172.279C173.363 578.304 174.245 579.185 174.245 580.27V641.951C174.245 643.035 173.363 643.917 172.279 643.917Z" fill="#4E5557"/>
    <path d="M168.711 650.406H47.3757L42.5565 650.4V644.028H171.872L171.866 650.301L168.711 650.406Z" fill="#373C3D"/>
    <path d="M161.71 643.301H159.96V651.059H161.71V643.301Z" fill="#8A969A"/>
    <path d="M51.3689 643.301H49.6188V651.059H51.3689V643.301Z" fill="#8A969A"/>
    <path d="M34.1631 651.38H43.0927V643.294H26.084C26.0778 647.762 29.7014 651.38 34.1631 651.38Z" fill="#373C3D"/>
    <path d="M32.9367 651.38H39.3828V645.094H26.6509C26.6509 648.569 29.4672 651.38 32.9367 651.38Z" fill="#924739"/>
    <path d="M176.321 651.38H167.392V643.294H184.401C184.407 647.762 180.789 651.38 176.321 651.38Z" fill="#373C3D"/>
    <path d="M177.548 651.38H171.102V645.094H183.834C183.834 648.569 181.023 651.38 177.548 651.38Z" fill="#924739"/>
    <path d="M31.0079 65.5095L7.25111 71.7337C1.55073 73.6133 -1.13615 77.7484 0.879009 81.5384L2.08071 83.7939L19.1141 78.796L22.3063 74.6486L31.0079 71.9741V65.5095Z" fill="#3350AB"/>
    <path d="M179.483 65.5095L203.24 71.7337C208.94 73.6133 211.627 77.7484 209.612 81.5384L208.41 83.7939L191.377 78.796L188.184 74.6486L179.483 71.9741V65.5095Z" fill="#3350AB"/>
    <path d="M170.073 148.384H40.4058V155.705H170.073V148.384Z" fill="#4F8FD0"/>
    <path d="M49.4956 239.214H26.3551C25.2705 239.214 24.3893 238.333 24.3893 237.248V175.567C24.3893 174.482 25.2705 173.601 26.3551 173.601H49.5018C50.5864 173.601 51.4676 174.482 51.4676 175.567V237.248C51.4615 238.333 50.5802 239.214 49.4956 239.214Z" fill="#373C3D"/>
    <path d="M186.434 239.214H163.288C162.203 239.214 161.322 238.333 161.322 237.248V175.567C161.322 174.482 162.203 173.601 163.288 173.601H186.434C187.519 173.601 188.4 174.482 188.4 175.567V237.248C188.4 238.333 187.519 239.214 186.434 239.214Z" fill="#373C3D"/>
    <path d="M49.4956 518.817H26.3551C25.2705 518.817 24.3893 517.935 24.3893 516.851V455.169C24.3893 454.085 25.2705 453.204 26.3551 453.204H49.5018C50.5864 453.204 51.4676 454.085 51.4676 455.169V516.851C51.4615 517.935 50.5802 518.817 49.4956 518.817Z" fill="#373C3D"/>
    <path d="M186.434 518.816H163.288C162.203 518.816 161.322 517.935 161.322 516.85V455.169C161.322 454.085 162.203 453.203 163.288 453.203H186.434C187.519 453.203 188.4 454.085 188.4 455.169V516.85C188.4 517.935 187.519 518.816 186.434 518.816Z" fill="#373C3D"/>
    <path d="M49.4956 589.686H26.3551C25.2705 589.686 24.3893 588.805 24.3893 587.72V526.039C24.3893 524.954 25.2705 524.073 26.3551 524.073H49.5018C50.5864 524.073 51.4676 524.954 51.4676 526.039V587.72C51.4615 588.805 50.5802 589.686 49.4956 589.686Z" fill="#373C3D"/>
    <path d="M186.434 589.686H163.288C162.203 589.686 161.322 588.805 161.322 587.72V526.039C161.322 524.954 162.203 524.073 163.288 524.073H186.434C187.519 524.073 188.4 524.954 188.4 526.039V587.72C188.4 588.805 187.519 589.686 186.434 589.686Z" fill="#373C3D"/>
    <path d="M48.3784 155.76V184.817H163.181V155.76H48.3784Z" fill="#4F8FD0"/>
    <path d="M32.7518 226.957V593.827C32.7518 630.47 52.6508 661.184 91.1423 661.4H120.562C158.037 661.184 178.374 630.371 178.337 593.827V226.957C178.337 225.848 178.312 224.738 178.256 223.641H32.8319C32.7765 224.738 32.7518 225.841 32.7518 226.957Z" fill="#EE9036"/>
    <path d="M32.832 224.258H178.25C176.568 189.649 154.9 162.114 120.803 162.114H90.2734C56.182 162.114 34.5083 189.642 32.832 224.258Z" fill="#894833"/>
    <path d="M32.7518 226.957V593.827C32.7518 630.47 52.6508 661.184 91.1423 661.4H120.562C158.037 661.184 178.374 630.371 178.337 593.827V226.957C178.337 225.848 178.312 224.738 178.256 223.641H32.8319C32.7765 224.738 32.7518 225.841 32.7518 226.957Z" fill="#894833"/>
    <path d="M106.191 661.08C106.191 661.08 83.4637 656.864 71.7548 635.912C64.0269 622.083 64.976 598.32 64.976 598.32H95.2219C95.2219 598.32 94.5564 622.97 97.0214 633.446C99.4864 643.923 106.191 661.08 106.191 661.08Z" fill="#A66353"/>
    <path d="M106.191 163C106.191 163 83.4637 167.215 71.7548 188.168C64.0269 201.997 64.976 225.76 64.976 225.76H95.2219C95.2219 225.76 94.5564 201.109 97.0214 190.633C99.4864 180.157 106.191 163 106.191 163Z" fill="#A66353"/>
    <path d="M95.1726 223.641H64.976V598.326H95.1726V223.641Z" fill="#A66353"/>
    <path d="M105.538 270.575C116.77 270.575 125.875 261.035 125.875 249.265C125.875 237.496 116.77 227.955 105.538 227.955C94.3065 227.955 85.2015 237.496 85.2015 249.265C85.2015 261.035 94.3065 270.575 105.538 270.575Z" fill="#A66353"/>
    <path d="M105.538 230.926C95.875 230.926 88.0363 239.134 88.0363 249.265C88.0363 259.397 95.8689 267.605 105.538 267.605C115.207 267.605 123.04 259.397 123.04 249.265C123.04 239.14 115.207 230.926 105.538 230.926ZM105.538 260.247C99.7513 260.247 95.0616 255.329 95.0616 249.265C95.0616 243.201 99.7513 238.284 105.538 238.284C111.325 238.284 116.014 243.201 116.014 249.265C116.014 255.329 111.325 260.247 105.538 260.247Z" fill="#894833"/>
    <path d="M105.649 340.071C116.881 340.071 125.986 330.53 125.986 318.761C125.986 306.991 116.881 297.45 105.649 297.45C94.4175 297.45 85.3125 306.991 85.3125 318.761C85.3125 330.53 94.4175 340.071 105.649 340.071Z" fill="#A66353"/>
    <path d="M105.649 300.421C95.986 300.421 88.1472 308.629 88.1472 318.761C88.1472 328.892 95.9799 337.101 105.649 337.101C115.318 337.101 123.151 328.892 123.151 318.761C123.151 308.629 115.312 300.421 105.649 300.421ZM105.649 329.736C99.8623 329.736 95.1726 324.819 95.1726 318.755C95.1726 312.691 99.8623 307.773 105.649 307.773C111.436 307.773 116.125 312.691 116.125 318.755C116.125 324.819 111.436 329.736 105.649 329.736Z" fill="#894833"/>
    <path d="M105.649 409.56C116.881 409.56 125.986 400.019 125.986 388.25C125.986 376.481 116.881 366.94 105.649 366.94C94.4175 366.94 85.3125 376.481 85.3125 388.25C85.3125 400.019 94.4175 409.56 105.649 409.56Z" fill="#A66353"/>
    <path d="M105.649 369.91C95.986 369.91 88.1472 378.119 88.1472 388.25C88.1472 398.381 95.9799 406.59 105.649 406.59C115.318 406.59 123.151 398.381 123.151 388.25C123.151 378.125 115.312 369.91 105.649 369.91ZM105.649 399.232C99.8623 399.232 95.1726 394.314 95.1726 388.25C95.1726 382.186 99.8623 377.268 105.649 377.268C111.436 377.268 116.125 382.186 116.125 388.25C116.125 394.314 111.436 399.232 105.649 399.232Z" fill="#894833"/>
    <path d="M105.649 479.055C116.881 479.055 125.986 469.515 125.986 457.745C125.986 445.976 116.881 436.435 105.649 436.435C94.4175 436.435 85.3125 445.976 85.3125 457.745C85.3125 469.515 94.4175 479.055 105.649 479.055Z" fill="#A66353"/>
    <path d="M105.649 439.406C95.986 439.406 88.1472 447.614 88.1472 457.745C88.1472 467.877 95.9799 476.085 105.649 476.085C115.318 476.085 123.151 467.877 123.151 457.745C123.151 447.614 115.312 439.406 105.649 439.406ZM105.649 468.727C99.8623 468.727 95.1726 463.809 95.1726 457.745C95.1726 451.681 99.8623 446.764 105.649 446.764C111.436 446.764 116.125 451.681 116.125 457.745C116.125 463.809 111.436 468.727 105.649 468.727Z" fill="#894833"/>
    <path d="M105.649 548.551C116.881 548.551 125.986 539.01 125.986 527.241C125.986 515.472 116.881 505.931 105.649 505.931C94.4175 505.931 85.3125 515.472 85.3125 527.241C85.3125 539.01 94.4175 548.551 105.649 548.551Z" fill="#A66353"/>
    <path d="M105.649 508.901C95.986 508.901 88.1472 517.109 88.1472 527.241C88.1472 537.372 95.9799 545.581 105.649 545.581C115.318 545.581 123.151 537.372 123.151 527.241C123.151 517.109 115.312 508.901 105.649 508.901ZM105.649 538.216C99.8623 538.216 95.1726 533.299 95.1726 527.235C95.1726 521.171 99.8623 516.253 105.649 516.253C111.436 516.253 116.125 521.171 116.125 527.235C116.125 533.299 111.436 538.216 105.649 538.216Z" fill="#894833"/>
    <path d="M105.649 618.04C116.881 618.04 125.986 608.499 125.986 596.73C125.986 584.961 116.881 575.42 105.649 575.42C94.4175 575.42 85.3125 584.961 85.3125 596.73C85.3125 608.499 94.4175 618.04 105.649 618.04Z" fill="#A66353"/>
    <path d="M105.649 578.39C95.986 578.39 88.1472 586.598 88.1472 596.73C88.1472 606.861 95.9799 615.07 105.649 615.07C115.318 615.07 123.151 606.861 123.151 596.73C123.151 586.598 115.312 578.39 105.649 578.39ZM105.649 607.711C99.8623 607.711 95.1726 602.794 95.1726 596.73C95.1726 590.666 99.8623 585.748 105.649 585.748C111.436 585.748 116.125 590.666 116.125 596.73C116.125 602.794 111.436 607.711 105.649 607.711Z" fill="#894833"/>
    <path d="M105.242 0.55603C64.2427 0.55603 31.0017 14.4033 31.0017 42.7327V138.253C31.0017 144.243 35.8578 149.105 41.854 149.105H168.624C174.614 149.105 179.477 144.249 179.477 138.253V42.7327C179.483 14.3972 146.242 0.55603 105.242 0.55603Z" fill="#26638F"/>
    <path d="M105.242 12.5175C134.946 12.5175 163.386 18.6986 178.836 36.0524C174.097 10.8783 142.97 0.549805 105.242 0.549805C67.5149 0.549805 36.3878 10.8783 31.6549 36.0524C46.9812 18.6986 75.5386 12.5175 105.242 12.5175Z" fill="#4F8FD0"/>
    <path d="M105.002 52.4572C69.1974 52.4572 40.4058 59.6489 40.4058 76.4419V87.4852V86.1418V142.332C40.2949 141.815 69.8075 138.733 105.618 138.733C141.429 138.733 170.325 141.815 170.073 142.332V86.1418V87.4852V76.4419C170.079 59.6489 140.813 52.4572 105.002 52.4572Z" fill="#4F8FD0"/>
    <path d="M159.356 116.985H51.1349V145.173H159.356V116.985Z" fill="#26638F"/>
    <path d="M159.356 116.986C159.356 100.193 134.89 91.8977 105.008 91.8977C75.1258 91.8977 51.1349 100.193 51.1349 116.986C51.1349 133.779 75.36 147.398 105.242 147.398C135.125 147.398 159.356 133.785 159.356 116.986Z" fill="#26638F"/>
    <path d="M58.0308 114.545L41.7801 63.0076C40.8865 65.0166 40.412 67.0995 40.412 69.2318V79.6527L52.0839 116.665L58.0308 114.545Z" fill="#26638F"/>
    <path d="M158.253 116.659L170.079 79.1596V69.2255C170.079 67.0255 169.555 64.8933 168.612 62.8226L152.306 114.539L158.253 116.659Z" fill="#26638F"/>
    <path d="M170.073 139.356H40.4058V146.017H170.073V139.356Z" fill="#26638F"/>
    </svg>`
)

export const cameraLayout1 = (
    <svg xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        zoomAndPan="magnify"
        viewBox="0 0 375 374.999991"
        preserveAspectRatio="xMidYMid meet"
        version="1.0">
        <defs><clipPath id="9db7cf916f"><path d="M 16.148438 16.148438 L 361.148438 16.148438 L 361.148438 361.148438 L 16.148438 361.148438 Z M 16.148438 16.148438 " clip-rule="nonzero" />
        </clipPath></defs><g clip-path="url(#9db7cf916f)"><path d="M 352.378906 16.148438 L 24.921875 16.148438 C 20.066406 16.148438 16.148438 20.066406 16.148438 24.921875 L 16.148438 352.378906 C 16.148438 357.230469 20.066406 361.148438 24.921875 361.148438 L 352.378906 361.148438 C 357.230469 361.148438 361.148438 357.230469 361.148438 352.378906 L 361.148438 24.921875 C 361.148438 20.066406 357.230469 16.148438 352.378906 16.148438 Z M 142.863281 234.4375 L 142.863281 142.863281 L 234.492188 142.863281 L 234.492188 234.492188 L 142.863281 234.492188 Z M 234.4375 251.976562 L 234.4375 343.609375 L 142.863281 343.609375 L 142.863281 251.976562 Z M 33.691406 142.863281 L 125.320312 142.863281 L 125.320312 234.492188 L 33.691406 234.492188 Z M 142.863281 125.320312 L 142.863281 33.691406 L 234.492188 33.691406 L 234.492188 125.320312 Z M 251.976562 142.863281 L 343.609375 142.863281 L 343.609375 234.492188 L 251.976562 234.492188 Z M 343.609375 125.320312 L 251.976562 125.320312 L 251.976562 33.691406 L 343.609375 33.691406 Z M 125.320312 33.691406 L 125.320312 125.320312 L 33.691406 125.320312 L 33.691406 33.691406 Z M 33.691406 251.976562 L 125.320312 251.976562 L 125.320312 343.609375 L 33.691406 343.609375 Z M 251.976562 343.609375 L 251.976562 251.976562 L 343.609375 251.976562 L 343.609375 343.609375 Z M 251.976562 343.609375 " fill-opacity="1" fill-rule="nonzero" /></g>
    </svg>
)

export const cameraLayout2 = (
    <svg xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        zoomAndPan="magnify"
        viewBox="0 0 375 374.999991"
        preserveAspectRatio="xMidYMid meet"
        version="1.0"><defs>
            <clipPath id="5aa3a70bec"><path d="M 14.925781 14.925781 L 359.925781 14.925781 L 359.925781 359.925781 L 14.925781 359.925781 Z M 14.925781 14.925781 " clip-rule="nonzero" />
            </clipPath></defs><g clip-path="url(#5aa3a70bec)"><path d="M 351.15625 14.925781 L 23.699219 14.925781 C 18.84375 14.925781 14.925781 18.84375 14.925781 23.699219 L 14.925781 351.15625 C 14.925781 356.007812 18.84375 359.925781 23.699219 359.925781 L 351.15625 359.925781 C 356.007812 359.925781 359.925781 356.007812 359.925781 351.15625 L 359.925781 23.699219 C 359.925781 18.84375 356.007812 14.925781 351.15625 14.925781 Z M 342.382812 178.65625 L 196.199219 178.65625 L 196.199219 32.46875 L 342.382812 32.46875 Z M 178.65625 32.46875 L 178.65625 178.65625 L 32.46875 178.65625 L 32.46875 32.46875 Z M 32.46875 196.199219 L 178.65625 196.199219 L 178.65625 342.382812 L 32.46875 342.382812 Z M 196.199219 342.382812 L 196.199219 196.199219 L 342.382812 196.199219 L 342.382812 342.382812 Z M 196.199219 342.382812 " fill-opacity="1" fill-rule="nonzero" /></g></svg>
)

export const cameraLayout3 = (
    <svg xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        zoomAndPan="magnify"
        viewBox="0 0 375 374.999991"
        preserveAspectRatio="xMidYMid meet"
        version="1.0">
        <defs>
            <clipPath id="ac0934bc1c"><path d="M 14.914062 15.078125 L 359.914062 15.078125 L 359.914062 359.914062 L 14.914062 359.914062 Z M 14.914062 15.078125 " clip-rule="nonzero" /></clipPath>
        </defs><g clip-path="url(#ac0934bc1c)">
            <path d="M 359.914062 23.6875 L 359.914062 351.144531 C 359.914062 355.996094 355.996094 359.914062 351.144531 359.914062 L 23.6875 359.914062 C 18.832031 359.914062 14.914062 355.996094 14.914062 351.144531 L 14.914062 23.6875 C 14.914062 18.832031 18.832031 14.914062 23.6875 14.914062 L 351.144531 14.914062 C 355.996094 14.914062 359.914062 18.832031 359.914062 23.6875 Z M 233.199219 342.375 L 233.199219 250.742188 L 141.628906 250.742188 L 141.628906 342.375 Z M 342.375 250.742188 L 250.742188 250.742188 L 250.742188 342.375 L 342.375 342.375 Z M 124.085938 342.375 L 124.085938 250.742188 L 32.457031 250.742188 L 32.457031 342.375 Z M 32.457031 32.457031 L 32.457031 233.199219 L 342.375 233.199219 L 342.375 32.457031 Z M 32.457031 32.457031 " fill-opacity="1" fill-rule="nonzero" /></g></svg>
)

export const filterIcon = (
    <svg class="svg-icon" width="100" height="100" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M640 288a64 64 0 1 1 0.032-128.032A64 64 0 0 1 640 288z m123.456-96c-14.304-55.04-64-96-123.456-96s-109.152 40.96-123.456 96H128v64h388.544c14.304 55.04 64 96 123.456 96s109.152-40.96 123.456-96H896V192h-132.544zM640 864a64 64 0 1 1 0.032-128.032A64 64 0 0 1 640 864m0-192c-59.456 0-109.152 40.96-123.456 96H128v64h388.544c14.304 55.04 64 96 123.456 96s109.152-40.96 123.456-96H896v-64h-132.544c-14.304-55.04-64-96-123.456-96M384 576a64 64 0 1 1 0.032-128.032A64 64 0 0 1 384 576m0-192c-59.456 0-109.152 40.96-123.456 96H128v64h132.544c14.304 55.04 64 96 123.456 96s109.152-40.96 123.456-96H896v-64H507.456c-14.304-55.04-64-96-123.456-96" /></svg>
)

export const privacyCameraIcon = (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#000000" class="bi bi-camera-video-off">
        <path fill-rule="evenodd" d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l.714 1H9.5a1 1 0 0 1 1 1v6a1 1 0 0 1-.144.518l.605.847zM1.428 4.18A.999.999 0 0 0 1 5v6a1 1 0 0 0 1 1h5.014l.714 1H2a2 2 0 0 1-2-2V5c0-.675.334-1.272.847-1.634l.58.814zM15 11.73l-3.5-1.555v-4.35L15 4.269v7.462zm-4.407 3.56-10-14 .814-.58 10 14-.814.58z" />
    </svg>
)