import React from 'react';
import classNames from 'classnames';
import './Spinner.scss';

function Spinner(props) {
  return (
    <>
      <div className={classNames('loader_overlay-div', props.fullScreen && 'position-fixed')} />
      <div className={classNames('loader', props.fullScreen && 'position-fixed')}>
        <div className="loader_inner ball-beat">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
}

Spinner.defaultProps = {
  fullScreen: false,
};

export default Spinner;
