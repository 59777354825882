import { toast } from 'react-toastify';
import './Notification.scss';

const Notify = ({
  message,
  position = toast.POSITION.TOP_RIGHT,
  toastType = 'success',
  autoClose = 5000,
  toastId,
}) =>
  toast[toastType](message, {
    position,
    autoClose,
    toastId,
  });

export default Notify;
