const {
  REACT_APP_API_URL,
  REACT_APP_MULTI_CAMERA_VEHICLE_NUMBER,
  REACT_APP_MULTI_CAMERA_ACCOUNT_USERNAME,
  REACT_APP_MULTI_CAMERA_ACCOUNT_PASSWORD,
  REACT_APP_MULTI_CAMERA_SRC,
  REACT_APP_WS_URL,
  REACT_APP_WS_DATA_FETCHING,
  REACT_APP_NOTIFICATIONS_ALLOWED_CLIENTIDS,
  REACT_APP_LIVE_STREAM_ALLOWED_VEHICLEIDS,
  REACT_APP_ALLOW_LIVE_STREAM_WITH_HTTP,
  REACT_APP_NOTIFICATION_ALLOWED_FOR_ALL,
  REACT_APP_GOOGLE_MAP_KEY,
  REACT_APP_MULTI_CAM_VIDEO_DURATION,
  REACT_APP_MULTI_CAM_VIDEO_DELAY,
  REACT_APP_MULTI_CAMERA_KEY,
  REACT_APP_MULTI_CAMERA_BASE_URL,
  REACT_APP_MULTI_CAMERA_ERROR_MESSAGE_CONTENT,
  REACT_APP_MULTI_CAMERA_ERROR_MESSAGE_FLAG,
  REACT_APP_MEDIAMTX_BASE_URL,
  REACT_APP_MEDIAMTX_CLOUD_TELEPHONY_VEHICLES,
  REACT_APP_MEDIAMTX_LIVE_STREAMING_VEHICLES,
  REACT_APP_ACTIONABLE_EVENT_LIMIT,
  REACT_APP_ACTIONABLE_ACTIVE_LIMIT,
  REACT_APP_MAINTENANCE_TICKET_ROUTE,
  REACT_APP_EVENT_DETAIL_ROUTE
} = process.env;

export const apiBaseUrl = REACT_APP_API_URL

export const wsBaseUrl = REACT_APP_WS_URL;

export const wsDataFetching = REACT_APP_WS_DATA_FETCHING === 'true';

export const multiCameraVehicleId = REACT_APP_MULTI_CAMERA_VEHICLE_NUMBER;

export const multiCameraAccountUsername = REACT_APP_MULTI_CAMERA_ACCOUNT_USERNAME;

export const multiCameraAccountPassword = REACT_APP_MULTI_CAMERA_ACCOUNT_PASSWORD;

export const multiCameraSrc = REACT_APP_MULTI_CAMERA_SRC;

export const notiticationsAllowedClientIds = REACT_APP_NOTIFICATIONS_ALLOWED_CLIENTIDS || [];

export const liveStreamAllowedVehicleIds = REACT_APP_LIVE_STREAM_ALLOWED_VEHICLEIDS || [];

export const allowLiveStreamWithHttp = REACT_APP_ALLOW_LIVE_STREAM_WITH_HTTP || false;

export const allowNotificationForAll = REACT_APP_NOTIFICATION_ALLOWED_FOR_ALL || false;

export const googleMapsApiKey = REACT_APP_GOOGLE_MAP_KEY

export const multiCameraKey = REACT_APP_MULTI_CAMERA_KEY

export const multiCameraBaseUrl = REACT_APP_MULTI_CAMERA_BASE_URL

export const mediamtxBaseUrl = REACT_APP_MEDIAMTX_BASE_URL

export const multiCameraErrorMessageContent = REACT_APP_MULTI_CAMERA_ERROR_MESSAGE_CONTENT

export const multiCameraErrorMessageFlag = REACT_APP_MULTI_CAMERA_ERROR_MESSAGE_FLAG === 'true'

export const multiCamVideoDuration = REACT_APP_MULTI_CAM_VIDEO_DURATION ? parseInt(REACT_APP_MULTI_CAM_VIDEO_DURATION) : 1

export const multiCamVideoDelay = REACT_APP_MULTI_CAM_VIDEO_DELAY ? parseInt(REACT_APP_MULTI_CAM_VIDEO_DELAY) : 5

export const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZHJpdmVidWRkeWFpIiwiYSI6ImNrYTJjbnBxcTA5MTAzcW9jbzRzeHQyeHQifQ.81ckHWbqnhI5llbHsPcKdg";

export const internetErrorResponseStoreDispatchDelay = "500"; //in ms

export const highAvailabilityServerAddress = "https://www.google.com";

export const featureRestrictedUsers = ['61fa832bd08add001c7ae14d'];

export const mediaMtxCloudTelephonyVehicles = REACT_APP_MEDIAMTX_CLOUD_TELEPHONY_VEHICLES ? REACT_APP_MEDIAMTX_CLOUD_TELEPHONY_VEHICLES.split(','):[]

export const mediaMtxLiveStreamingVehicles = REACT_APP_MEDIAMTX_LIVE_STREAMING_VEHICLES ? REACT_APP_MEDIAMTX_LIVE_STREAMING_VEHICLES.split(',') : [];

export const actionableEventLimit = REACT_APP_ACTIONABLE_EVENT_LIMIT ? parseInt(REACT_APP_ACTIONABLE_EVENT_LIMIT) : 15;

export const actionableActiveLimit = REACT_APP_ACTIONABLE_ACTIVE_LIMIT ? parseInt(REACT_APP_ACTIONABLE_ACTIVE_LIMIT) : 5;
export const maintenanceTicketRoute = REACT_APP_MAINTENANCE_TICKET_ROUTE || '/maintenance'
export const eventDetailRoute = REACT_APP_EVENT_DETAIL_ROUTE || '/events'
