//index reducer, one reducer to rule them all
import { combineReducers } from 'redux';

//reducer imports
import authenticationReducer from './authentication';
import appReducer from './details';
import statisticReducer from './statistics';

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  app: appReducer,
  statistic: statisticReducer,
});

export default rootReducer;
